import AudioHowl from '@phoenix7dev/play-music';
import * as PIXI from 'pixi.js';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import Animation from '../animations/animation';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import {
  eventManager,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
} from '../config';
import {
  ANIMATIONS,
  ANIMATIONS_STEPS,
  createBounceAnimation,
  FREE_SPIN_SPIN_ANIMATION_SIZE,
  textStyle,
} from './helperConfig';

class FreeSpinsMultipliersAnimationContainer extends ViewContainer {
  private multipliersAnimation: SpineAnimation | null = null;

  private animationStepIndex: number;

  private hasStop: boolean;

  private multiplier = new PIXI.Text('x0', textStyle);

  private multiplierAnimation: Animation | null = null;

  private hasForceStop = false;

  constructor() {
    super();
    this.x = GAME_CONTAINER_WIDTH / 2;
    this.y = GAME_CONTAINER_HEIGHT / 2;
    this.zIndex = 5;
    this.animationStepIndex = 0;
    this.hasStop = false;
    this.sortableChildren = true;
    this.multiplier.visible = false;
    this.multiplier.anchor.set(0.5, 0.5);
    this.multiplier.x = 10;
    this.multiplier.y = 38.22;
    this.multiplier.zIndex = 2;
    this.addChild(this.multiplier);
    this.scale.set(GAME_CONTAINER_WIDTH / FREE_SPIN_SPIN_ANIMATION_SIZE);
    this.initAnimation();

    eventManager.addListener(
      EventTypes.SHOW_FREE_SPINS_MULTIPLIER_ANIMATION,
      (multiplier: number): void => {
        this.multiplier.text = `x${multiplier}`;
        this.hasStop = false;
        this.animationStepIndex = 0;
        this.startAnimation(ANIMATIONS_STEPS[this.animationStepIndex]);
      },
    );

    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {
      this.removeAnimation();
    });
  }

  private initAnimation(): void {
    this.multipliersAnimation = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.multipliers.spineData,
    );
    this.multipliersAnimation.addOnStart(() => {
      this.addChild(this.multipliersAnimation!.spine);
    });
  }

  private startAnimation(anim: string): void {
    this.multipliersAnimation!.setAnimation(anim, false);
    this.multipliersAnimation!.start();
    this.playSound(this.animationStepIndex);
    this.multiplier.visible =
      this.animationStepIndex === ANIMATIONS.loop_cannon_bomb;
    if (this.animationStepIndex === ANIMATIONS.loop_cannon_bomb) {
      this.startMultiplierAnimation();
    }
    this.multipliersAnimation!.complete = [];
    this.multipliersAnimation!.addOnComplete(() => {
      this.onCompleteAnimation();
    });
  }

  private startMultiplierAnimation(): void {
    this.multiplierAnimation = createBounceAnimation(this.multiplier);
    this.multiplierAnimation.start();
  }

  private onCompleteAnimation(): void {
    if (
      this.animationStepIndex === ANIMATIONS.out_cannon_bomb &&
      !this.hasStop
    ) {
      eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_MULTIPLIER);
    }

    this.animationStepIndex += 1;
    if (this.hasForceStop) {
      this.hasForceStop = false;
    }
    if (this.animationStepIndex === ANIMATIONS_STEPS.length) {
      this.animationStepIndex = 0;
      this.hasStop = true;
    }

    if (!this.hasStop) {
      this.startAnimation(ANIMATIONS_STEPS[this.animationStepIndex]);
    }
  }

  private removeAnimation(): void {
    if (
      !this.hasStop &&
      (this.animationStepIndex === ANIMATIONS.in_cannon_bomb ||
        this.animationStepIndex === ANIMATIONS.loop_cannon_bomb)
    ) {
      this.hasForceStop = true;
      this.multipliersAnimation?.skip();
      this.animationStepIndex = 2;
      this.startAnimation(ANIMATIONS_STEPS[this.animationStepIndex]);
    }
  }

  private playSound(step: number): void {
    if (step === ANIMATIONS.in_cannon_bomb) {
      AudioHowl.play({ type: ISongs.MPDisp, stopPrev: true });
    }
    if (step === ANIMATIONS.out_cannon_bomb && !this.hasForceStop) {
      AudioHowl.play({ type: ISongs.CannonBallFire, stopPrev: true });
    }
    if (step === ANIMATIONS.out_cannon_explosion) {
      AudioHowl.play({ type: ISongs.CannonBallLand, stopPrev: true });
    }
  }
}
export default FreeSpinsMultipliersAnimationContainer;
