import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import {
  eventManager,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
} from '../config';
import { IWinLine } from '../d';
import Line from './line';

class LinesContainer extends PIXI.Container {
  public linesData: number[][];

  public lines: Line[];

  constructor(linesData: number[][]) {
    super();
    this.linesData = linesData;
    this.lines = [];
    this.initContainer();
    this.initLines();
    eventManager.addListener(
      EventTypes.SHOW_WIN_LINES,
      this.showLines.bind(this),
    );
    eventManager.addListener(
      EventTypes.HIDE_WIN_LINES,
      this.hideLines.bind(this),
    );
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.scale.x = 1;
    this.scale.y = 1;
  }

  private initLines(): void {
    for (let i = 0; i < this.linesData.length; i++) {
      const line = new Line(i, this.linesData[i]);
      this.lines.push(line);
      this.addChild(line);
    }
  }

  public showLines(lines: IWinLine[]): void {
    lines.forEach((line) => {
      if (line.lineId !== null) this.setLineVisibility(line.lineId, true);
    });
  }

  public hideLines(lines: IWinLine[]): void {
    if (!lines) {
      for (let i = 0; i < this.lines.length; i++) {
        this.setLineVisibility(i, false);
      }
    } else {
      lines.forEach((line) => {
        if (line.lineId !== null) this.setLineVisibility(line.lineId, false);
      });
    }
  }

  public setLineVisibility(id: number, visibility: boolean): void {
    this.lines[id].visible = visibility;
  }
}

export default LinesContainer;
