import * as PIXI from 'pixi.js';

import { isMobile } from 'mobile-device-detect';
import { EventTypes } from '../../global.d';
import i18n from '../../i18next';
import { updateTextScale } from '../../utils/utils';
import SlotMachine from '..';
import { TweenProperties } from '../animations/d';
import { createZoomAnimation } from '../animations/helper';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { eventManager } from '../config';
import {
  RETRIGGER_MESSAGE_DELAY_DURATION,
  RETRIGGER_MESSAGE_FADEOUT_DURATION,
  RETRIGGER_MESSAGE_POSITION_X,
  RETRIGGER_MESSAGE_POSITION_Y,
  RETRIGGER_MESSAGE_ZOOM_DURATION,
  RETRIGGER_MESSAGE_ZOOM_SCALE,
  retriggerMessageStyle,
} from './config';

class RetriggerMessage extends ViewContainer {
  private text: PIXI.Text;

  constructor(freeSpins: number) {
    super();

    this.text = new PIXI.Text(
      i18n.t('freeSpinsMessageBannerSubtitle', { var: `+${freeSpins}` }),
      retriggerMessageStyle,
    );
    this.text.resolution = 2;
    this.text.anchor.set(0.5);
    this.text.x = RETRIGGER_MESSAGE_POSITION_X;
    this.text.y = RETRIGGER_MESSAGE_POSITION_Y;
    updateTextScale(
      this.text,
      isMobile ? 700 : 800,
      isMobile ? 250 : 250,
    );
    this.text.resolution = 1;
    this.startRetrigger();
  }

  private startRetrigger(): void {
    this.addChild(this.text);

    const animation = createZoomAnimation(
      this.text,
      RETRIGGER_MESSAGE_ZOOM_SCALE * this.text.scale.x,
      RETRIGGER_MESSAGE_ZOOM_DURATION,
      false,
      this.text.scale.x,
    );
    const delay = Tween.createDelayAnimation(RETRIGGER_MESSAGE_DELAY_DURATION);
    animation.appendAnimation(delay);

    const fadeOut = new Tween({
      object: this.text,
      target: 0,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      duration: RETRIGGER_MESSAGE_FADEOUT_DURATION,
    });
    animation.appendAnimation(fadeOut);

    animation.addOnComplete(() => {
      this.handleDestroy();
    });

    animation.start();
  }

  private handleDestroy(): void {
    SlotMachine.getInstance().gameView.removeChild(this);
    eventManager.emit(EventTypes.END_RETRIGGER_FEATURE);
    this.destroy({ children: true, texture: true, baseTexture: true });
  }
}

export default RetriggerMessage;
