import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setIsTransitionStarted,
} from '../../gql';
import { calcPercentage, isFreeRoundBonusMode, isRegularMode } from '../../utils';
import { eventManager } from '../config';
import Button from '.';

class BetBtn extends Button {
  private gameMode: GameMode;

  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  private isFreeRoundBonus = false;

  constructor() {
    super('bet-settings', 60);
    this.interactive = !this.isDisabled;
    if (setBrokenGame()) {
      this.gameMode = setGameMode();
    } else {
      this.gameMode = GameMode.REGULAR;
    }
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.isFreeRoundBonus = isFreeRoundBonusMode(setGameMode());
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.isFreeRoundBonus = isFreeRoundBonusMode(data.mode);
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(
      EventTypes.SET_IS_SPIN_IN_PROGRESS,
      (isSpinInProgress: boolean) => {
        this.isSpinInProgress = isSpinInProgress;
        this.handleDisable();
      },
    );
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });

    eventManager.on(
      EventTypes.SET_IS_FREESPINS_WIN,
      (isFreeSpinWin: boolean) => {
        this.isFreeSpinsWin = isFreeSpinWin;
        this.handleDisable();
      },
    );
    eventManager.on(EventTypes.START_MODE_CHANGE_FADE, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.END_MODE_CHANGE_FADE, () => {
      this.handleDisable();
    });

  };

  private handleClick = (): void => {
    if (
      !this.isDisabled &&
      setIsModalOpeningInProgress() === ModalOpeningTypes.NONE
    ) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BET_SETTINGS);

      setIsOpenBetSettingsModal(!setIsOpenBetSettingsModal());
      setIsOpenAutoplayModal(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(
        () => setIsModalOpeningInProgress(ModalOpeningTypes.NONE),
        100,
      );
    }
  };

  private handleDisable = (): void => {
    this.setDisable(
      !isRegularMode(this.gameMode) ||
        this.isFreeRoundBonus ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        setIsContinueAutoSpinsAfterFeature() ||
        setIsTransitionStarted(),
    );
  };

  public handlePosition(): void {
    let x = this.isLeftHandMode
      ? calcPercentage(this.applicationSize.width, 18)
      : calcPercentage(this.applicationSize.width, 82);
    let y = calcPercentage(this.applicationSize.height, 95);
    this.setSize(calcPercentage(this.applicationSize.width, 4));

    if (this.isPortraitMode) {
      this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 20));
      x = this.isLeftHandMode
        ? calcPercentage(this.underGameContainerSpaceHeight, 20)
        : this.applicationSize.width -
          calcPercentage(this.underGameContainerSpaceHeight, 20);
      y =
        this.gameContainerBottomPosition +
        calcPercentage(this.underGameContainerSpaceHeight, 35);
    }
    if (this.isLandscapeMode) {
      this.setSize(calcPercentage(this.applicationSize.height, 10.4));
      x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 9)
        : calcPercentage(this.applicationSize.width, 91);
      y = calcPercentage(this.applicationSize.height, 12.5);
    }

    this.x = x;
    this.y = y;
  }
}

export default BetBtn;
