import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';

export const FREE_SPINS_TITLE_POSITION_X = 0;
export const FREE_SPINS_TITLE_POSITION_Y = 0;
export const FREE_SPINS_TITLE_WIDTH = 0;
export const FREE_SPINS_TITLE_HEIGHT = 0;

export const FREE_SPIN_ANIMATION_SCALE = 1.3;
export const FREE_SPIN_ANIMATION_DELAY = 500;
export const FREE_SPIN_ANIMATION_LOOP = false;

export const FREE_SPIN_SPIN_ANIMATION_SIZE = 1720;

export const ANIMATIONS_STEPS = [
  'in_cannon_bomb',
  'loop_cannon_bomb',
  'out_cannon_bomb',
  'out_cannon_explosion',
];

export const ANIMATIONS = {
  in_cannon_bomb: 0,
  loop_cannon_bomb: 1,
  out_cannon_bomb: 2,
  out_cannon_explosion: 3,
};

const font = 'NotoSans-SemiCondensedBold';

export const textStyle = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: font,
  fontSize: 90,
  fontStyle: 'normal',
  fontVariant: 'normal',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#000000',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
};

export const createBounceAnimation = (object: PIXI.Text): AnimationChain => {
  const animationChain = new AnimationChain();
  const downY = new Tween({
    object,
    target: 51.94,
    property: TweenProperties.Y,
    propertyBeginValue: 38.22,
    duration: 166.7,
  });
  const upY = new Tween({
    object,
    target: 6.42,
    property: TweenProperties.Y,
    propertyBeginValue: 51.94,
    duration: 500,
  });
  const stopY = new Tween({
    object,
    target: 38.22,
    property: TweenProperties.Y,
    propertyBeginValue: 6.42,
    duration: 333.3,
  });
  animationChain.appendAnimation(downY);
  animationChain.appendAnimation(upY);
  animationChain.appendAnimation(stopY);

  return animationChain;
};
