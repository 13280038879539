import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoFeatureFreeSpinDescs = [
    { key: 'infoFeatureFreeSpinDesc_1', text: i18n.t('infoFeatureFreeSpinDesc_1') },
    { key: 'infoFeatureFreeSpinDesc_2', text: i18n.t('infoFeatureFreeSpinDesc_2') },
    { key: 'infoFeatureFreeSpinDesc_3', text: i18n.t('infoFeatureFreeSpinDesc_3') },
    { key: 'infoFeatureFreeSpinDesc_4', text: i18n.t('infoFeatureFreeSpinDesc_4') },
    { key: 'infoFeatureFreeSpinDesc_5', text: i18n.t('infoFeatureFreeSpinDesc_5') },
    { key: 'infoFeatureFreeSpinDesc_6', text: i18n.t('infoFeatureFreeSpinDesc_6') },
    { key: 'infoFeatureFreeSpinDesc_7', text: i18n.t('infoFeatureFreeSpinDesc_7') },
    { key: 'infoFeatureFreeSpinDesc_8', text: i18n.t('infoFeatureFreeSpinDesc_8') },
    { key: 'infoFeatureFreeSpinDesc_9', text: i18n.t('infoFeatureFreeSpinDesc_9') },
    { key: 'infoFeatureFreeSpinDesc_10', text: i18n.t('infoFeatureFreeSpinDesc_10') },
    { key: 'infoFeatureFreeSpinDesc_11', text: i18n.t('infoFeatureFreeSpinDesc_11') },
  ];


  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeatureFreeSpinTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFeatureFreeSpinDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles.gameRules}>
      <FreeSpinFeature />
    </div>
  );
};

export default Features;
