import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';
import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.pirateA,
  [SlotId.B]: ResourceTypes.pirateB,
  [SlotId.C]: ResourceTypes.pirateC,
  [SlotId.D]: ResourceTypes.gun,
  [SlotId.E]: ResourceTypes.cutlass,
  [SlotId.F]: ResourceTypes.compass,
  [SlotId.G]: ResourceTypes.telescope,
  [SlotId.WL]: ResourceTypes.wild,
  [SlotId.SC1]: ResourceTypes.scatter,
  [SlotId.SC2]: ResourceTypes.scatter,
};

export const MAPPED_BLURRED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.blurPirateA,
  [SlotId.B]: ResourceTypes.blurPirateB,
  [SlotId.C]: ResourceTypes.blurPirateC,
  [SlotId.D]: ResourceTypes.blurGun,
  [SlotId.E]: ResourceTypes.blurCutlass,
  [SlotId.F]: ResourceTypes.blurCompass,
  [SlotId.G]: ResourceTypes.blurTelescope,
  [SlotId.WL]: ResourceTypes.wild,
  [SlotId.SC1]: ResourceTypes.scatter,
  [SlotId.SC2]: ResourceTypes.scatter,
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_a',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_b',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_c',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_d',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_e',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_f',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_g',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'wild',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'bonus_announce',
  },
  [SlotId.SC2]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'bonus_win',
  },
};

export const LOADER_MAPPED_SYMBOLS = [
  {
    name: ResourceTypes.pirateA,
    src: Resources.getSource(ResourceTypes.pirateA),
  },
  {
    name: ResourceTypes.pirateB,
    src: Resources.getSource(ResourceTypes.pirateB),
  },
  {
    name: ResourceTypes.pirateC,
    src: Resources.getSource(ResourceTypes.pirateC),
  },
  { name: ResourceTypes.gun, src: Resources.getSource(ResourceTypes.gun) },
  {
    name: ResourceTypes.cutlass,
    src: Resources.getSource(ResourceTypes.cutlass),
  },
  {
    name: ResourceTypes.compass,
    src: Resources.getSource(ResourceTypes.compass),
  },
  {
    name: ResourceTypes.telescope,
    src: Resources.getSource(ResourceTypes.telescope),
  },
  { name: ResourceTypes.wild, src: Resources.getSource(ResourceTypes.wild) },
  {
    name: ResourceTypes.scatter,
    src: Resources.getSource(ResourceTypes.scatter),
  },
];

export const LOADER_TEXTURES = (isMobile: boolean) => [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.blurPirateA,
    src: Resources.getSource(ResourceTypes.blurPirateA),
  },
  {
    name: ResourceTypes.blurPirateB,
    src: Resources.getSource(ResourceTypes.blurPirateB),
  },
  {
    name: ResourceTypes.blurPirateC,
    src: Resources.getSource(ResourceTypes.blurPirateC),
  },
  {
    name: ResourceTypes.blurGun,
    src: Resources.getSource(ResourceTypes.blurGun),
  },
  {
    name: ResourceTypes.blurCutlass,
    src: Resources.getSource(ResourceTypes.blurCutlass),
  },
  {
    name: ResourceTypes.blurCompass,
    src: Resources.getSource(ResourceTypes.blurCompass),
  },
  {
    name: ResourceTypes.blurTelescope,
    src: Resources.getSource(ResourceTypes.blurTelescope),
  },
  {
    name: ResourceTypes.shortLine,
    src: Resources.getSource(ResourceTypes.shortLine),
  },
  {
    name: ResourceTypes.mediumLine,
    src: Resources.getSource(ResourceTypes.mediumLine),
  },
  {
    name: ResourceTypes.largeLine,
    src: Resources.getSource(ResourceTypes.largeLine),
  },
  {
    name: ResourceTypes.messageBanner,
    src: Resources.getSource(ResourceTypes.messageBanner),
  },
  {
    name: 'coinsAnimation',
    src: '/animations/desktop/coins/coins.json',
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.introTextBg,
    src: Resources.getSource(ResourceTypes.introTextBg),
  },
  {
    name: ResourceTypes.introLine,
    src: Resources.getSource(ResourceTypes.introLine),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.buttonPlay,
    src: Resources.getSource(ResourceTypes.buttonPlay),
  },
  {
    name: ResourceTypes.slotFreeSpinBg,
    src: Resources.getSource(ResourceTypes.slotFreeSpinBg),
  },
  {
    name: ResourceTypes.portraitSlotFreeSpinBg,
    src: Resources.getSource(ResourceTypes.portraitSlotFreeSpinBg),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
];

export const generateTexturePath = (
  before: string,
  after: string,
  isMobile: boolean,
): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};

export const SPINE_LOADER_TEXTURES = (isMobile: boolean) => {
  return [
    {
      name: 'ambient',
      src: generateTexturePath('/animations', 'ambient/ambient.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['ambient.png', 'ambient1.jpg'],
        mobile: ['ambient.png', 'ambient1.jpg'],
      },
    },
    {
      name: 'logo',
      src: '/animations/desktop/logo/logo.json',
      isSpine: true,
      spineImages: ['logo.png'],
    },
    {
      name: 'symbols',
      src: '/animations/desktop/symbol_win/symbols.json',
      isSpine: true,
      spineImages: ['symbols.png', 'symbols1.jpg'],
    },
    {
      name: 'multipliers',
      src: '/animations/desktop/free_spins/multipliers.json',
      isSpine: true,
      spineImages: ['multipliers.png', 'multipliers1.jpg'],
    },
    {
      name: 'near_miss',
      src: '/animations/desktop/near_miss/near_miss.json',
      isSpine: true,
      spineImages: ['near_miss.jpg', 'near_miss1.jpg'],
    },
  ];
};
