import _ from "lodash";
import { SlotId } from "../config";
import { EventTypes } from "../global.d";
import { setGameMode, setIsErrorMessage, setPrevReelsPosition, setSlotConfig, setUserLastBetResult } from "../gql";
import { ANTICIPATION_ENABLE, ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME, ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE, ANTICIPATION_SYMBOLS_ID, REELS_AMOUNT, eventManager } from "../slotMachine/config";
import { Icon } from "../slotMachine/d";
import { isFreeSpinMode } from "./helper";

export const getAnticipationReelId = (spinResult: Icon[]): number => {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
        const count = isFreeSpinMode(setGameMode())
            ? ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE[i]
            : ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME[i];
        let currentCount = 0;
        for (let j = 0; j < REELS_AMOUNT; j++) {
            // eslint-disable-next-line no-plusplus
            if (spinResult[j].id === symbolId) currentCount++;
            // eslint-disable-next-line no-plusplus
            if (spinResult[j + REELS_AMOUNT].id === symbolId) currentCount++;
            // eslint-disable-next-line no-plusplus
            if (spinResult[j + REELS_AMOUNT * 2].id === symbolId) currentCount++;

            if (currentCount >= count) minReelId = Math.min(minReelId, j);
        }
    });
    return minReelId;
}

export const getScatterCount = (spinResult: Icon[]): number[] => {
    let count = 0;
    return _(spinResult)
        .chunk(REELS_AMOUNT)
        .unzip()
        .map((col) => {
            if (
                col.some((icon) => icon.id === SlotId.SC1 || icon.id === SlotId.SC2)
            ) {
                count += 1;
                return count;
            }
            return 0;
        })
        .value();
}


export const fallBackReelPosition = () => {
    setIsErrorMessage(true);
    const startPosition = setUserLastBetResult().id
        ? setUserLastBetResult().result.reelPositions
        : setPrevReelsPosition();

    eventManager.emit(
        EventTypes.SETUP_REEL_POSITIONS,
        startPosition,
        setUserLastBetResult().result.spinResult?.length ? getScatterCount(setUserLastBetResult().result.spinResult!) : [],
        setUserLastBetResult().result.spinResult?.length
            ? getAnticipationReelId(setUserLastBetResult().result.spinResult!)
            : 5,
    );
    eventManager.emit(EventTypes.FORCE_STOP_REELS);
    eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
};
