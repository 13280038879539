import { makeVar } from '@apollo/client';
import AudioHowl from '@phoenix7dev/play-music';

import { getUserConfig } from '@phoenix7dev/utils-fe';
import {
  BonusStatus,
  GameMode,
  IBonus,
  ModalOpeningTypes,
  reelSets,
  UserBonus,
} from '../global.d';
import { IBet, ISlotConfig, IConfig, IStressful } from './d';

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<{ status: number; wasLoaded?: boolean }>({
  status: 0,
  wasLoaded: false,
});

export const setIsLeftHandMode = makeVar<boolean>(
  getUserConfig<IConfig>('config', 'isLeftHandMode', false),
);

export const setIsSoundOn = makeVar<boolean>(
  getUserConfig<IConfig>('config', 'isSoundOn', true),
);

export const setIsShowSoundToast = makeVar<boolean>(AudioHowl.isRestricted);

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setIsSuspended = makeVar<boolean>(false);

export const setBrokenGame = makeVar<boolean>(false);

export const setPrevReelsPosition = makeVar<number[]>([0, 0, 0, 0, 0]);

export const setUserLastBetResult = makeVar<IBet>({
  id: '',
  coinAmount: 1,
  coinValue: 1,
  result: {
    reelPositions: [],
    winCoinAmount: 0,
  },
  reelSetId: '',
  createdAt: '',
  updatedAt: '',
});

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setSoundValue = makeVar<number>(Number(setIsSoundOn()));

export const setIsSpinInProgress = makeVar<boolean>(false);

export const setIsPortrait = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(25);

export const setCoinValue = makeVar<number>(1);

export const setCoinAmount = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(0);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsContinueAutoSpinsAfterFeature = makeVar<boolean>(false);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(false);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

// todo replace with real backend logic
export const setFreeSpinsTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.REGULAR);

export const setCurrency = makeVar<string>('FUN');

export const setReelSetId = makeVar<string>('');

export const setCurrentBonusId = makeVar<string>('');

export const setCurrentBonus = makeVar<UserBonus>({
  id: '',
  isActive: false,
  bonusId: '',
  currentRound: 0,
  gameMode: GameMode.FREE_SPINS,
  status: BonusStatus.INACTIVE,
  data: {
    frbReferenceId: null,
  },
  rounds: 10,
  reelSetId: reelSets[GameMode.REGULAR],
  totalWinAmount: 0,
  betId: '',
  coinAmount: 1,
  coinValue: 1,
  roundsPlayed: 0,
});

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setSlotConfig = makeVar<ISlotConfig>({
  id: '',
  lineSet: {
    id: '',
    slotId: '',
    lines: [],
    coinAmountMultiplier: 0,
  },
  clientSettings: {
    coinAmounts: {
      default: [],
      quick: [],
    },
    coinValues: [],
    features: [],
    autoplay: {
      options: [],
      conditions: {
        stopOnAnyWin: {
          enabled: false,
        },
        stopIfFeatureIsWon: {
          enabled: false,
        },
        stopIfSingleWinExceeds: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceDecreasesBy: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceIncreasesBy: {
          enabled: false,
          multipliers: [],
        },
      },
    },
  },
  lines: [],
  sessionId: '',
  reels: [],
  icons: [],
  winLines: [],
});

export const setBonuses = makeVar<IBonus[]>([]);

export const setGameHistory = makeVar<boolean[]>([false, false, false]);

export const setIsTurboSpin = makeVar<boolean>(
  getUserConfig<IConfig>('config', 'isTurboSpin', false),
);

export const setIsMiniPayTable = makeVar<boolean>(
  getUserConfig<IConfig>('config', 'isMiniPayTable', true),
);

export const setIsEnabledSpaceSpin = makeVar<boolean>(
  getUserConfig<IConfig>('config', 'isEnabledSpaceSpin', true),
);

export const setSkipIntroScreen = makeVar<boolean>(
  getUserConfig<IConfig>('config', 'isSkipIntroScreen', false),
);

export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsOpenMenuModal = makeVar<{
  isOpen: boolean;
  type: 'menu' | 'info';
}>({
  isOpen: false,
  type: 'menu',
});
export const setIsOpenBetSettingsModal = makeVar<boolean>(false);
export const setIsOpenAutoplayModal = makeVar<boolean>(false);
export const setIsModalOpeningInProgress = makeVar<ModalOpeningTypes>(
  ModalOpeningTypes.NONE,
);
export const setIsDuringBigWinLoop = makeVar<boolean>(false);

export const setIsSoundLoading = makeVar<boolean>(false);

export const setIsProcessToGame = makeVar<boolean>(false);

export const setIsErrorMessage = makeVar<boolean>(false);
export const setIsTimeoutErrorMessage = makeVar<boolean>(false);
export const setReplayBet = makeVar<string>('');
export const setReplayFreeSpinBets = makeVar<string[]>([]);
export const setReplayFreeSpinReelSetId = makeVar<string>('');


export const setFreeRoundsTotalWin = makeVar<number>(0);

export const setBottomContainerTotalWin = makeVar<number>(0);

export const setIsPopupOpened = makeVar<boolean>(false);

export const setFreeRoundsBonus = makeVar<UserBonus>({
  id: '',
  isActive: false,
  bonusId: '',
  currentRound: 0,
  gameMode: GameMode.FREE_ROUND_BONUS,
  status: BonusStatus.INACTIVE,
  data: {
    frbReferenceId: null,
  },
  rounds: 10,
  reelSetId: reelSets[GameMode.REGULAR],
  totalWinAmount: 0,
  betId: '',
  coinAmount: 1,
  coinValue: 1,
  roundsPlayed: 0,
});

export const setIsTransitionStarted = makeVar<boolean>(false);


