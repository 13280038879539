export enum ResourceTypes {
  messageBanner = 'messageBanner',
  frame = 'frame',
  largeLine = 'largeLine',
  logo = 'logo',
  mediumLine = 'mediumLine',
  shortLine = 'shortLine',
  blurCompass = 'blurCompass',
  blurCutlass = 'blurCutlass',
  blurGun = 'blurGun',
  blurPirateA = 'blurPirateA',
  blurPirateB = 'blurPirateB',
  blurPirateC = 'blurPirateC',
  blurTelescope = 'blurTelescope',
  compass = 'compass',
  cutlass = 'cutlass',
  gun = 'gun',
  pirateA = 'pirateA',
  pirateB = 'pirateB',
  pirateC = 'pirateC',
  scatter = 'scatter',
  telescope = 'telescope',
  wild = 'wild',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonPlay = 'buttonPlay',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  introBg = 'introBg',
  introLine = 'introLine',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  introTextBg = 'introTextBg',
  portraitSlotFreeSpinBg = 'portraitSlotFreeSpinBg',
  slotBg = 'slotBg',
  slotFreeSpinBg = 'slotFreeSpinBg',
}
