import { AudioSprite } from '@phoenix7dev/play-music/dist/d';
import _ from 'lodash';

export enum ISongs {
  BaseGameBGM_Base = '004_01_BaseGameBGM_Base',
  BaseGameBGM_Melo = '004_02_BaseGameBGM_Melo',
  // FreeSpinBGM_Intro = '002_03_FreeSpinBGM_Intro', NO SOUND PROVIDED
  FreeSpinBGM_Loop = '004_03_FreeSpinBGM',
  // BigWin_Intro = '002_04_BigWin_Intro', NO SOUND PROVIDED
  BigWin_Loop = '004_04_BigWin_Loop',
  BigWin_End = '004_05_BigWin_END',
  // BigWin_Start = '002_06_BigWin_Start', NO SOUND PROVIDED
  Scatter_01 = '004_06_Scatter_01',
  Scatter_02 = '004_07_Scatter_02',
  Scatter_03 = '004_08_Scatter_03',
  Scatter_04 = '004_09_Scatter_04',
  Scatter_05 = '004_10_Scatter_05',
  LongSpin = '004_11_LongSpin',
  FeatureTrigger = '004_12_FeatureTrigger',
  FeatureReTrigger = '004_13_FeatureReTrigger',
  MPDisp = '004_14_MPDisp',
  CannonBallFire = '004_15_CannonBallFire',
  CannonBallLand = '004_16_CannonBallLand',
  TotalWinBanner = '004_17_TotalWinBanner',
  Win_End = 'SFX_SM_CountUp_End',
  Win_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_BIG = 'SFX_WIN_BIG',
  SFX_WIN_EPIC = 'SFX_WIN_EPIC',
  SFX_WIN_GREAT = 'SFX_WIN_GREAT',
  SFX_WIN_MEGA = 'SFX_WIN_MEGA',
}

export const audioSprite: AudioSprite = {
  [ISongs.BaseGameBGM_Base]: [0, 77431.38321995466, true],
  [ISongs.BaseGameBGM_Melo]: [79000, 77431.38321995466, true],
  // [ISongs.FreeSpinBGM_Intro]: [124000, 18000],
  [ISongs.FreeSpinBGM_Loop]: [158000, 52048.25396825396, true],
  // [ISongs.BigWin_Intro]: [210000, 888.9342403628007],
  [ISongs.BigWin_Loop]: [212000, 30720, true],
  [ISongs.BigWin_End]: [244000, 4586.666666666673],
  // [ISongs.BigWin_Start]: [252000, 3372.086167800461],
  [ISongs.Scatter_01]: [250000, 175.73696145123563],
  [ISongs.Scatter_02]: [252000, 162.44897959182936],
  [ISongs.Scatter_03]: [254000, 502.53968253969106],
  [ISongs.Scatter_04]: [256000, 519.4104308390024],
  [ISongs.Scatter_05]: [258000, 519.9999999999818],
  [ISongs.LongSpin]: [260000, 1743.945578231319],
  [ISongs.FeatureTrigger]: [263000, 879.8639455782222],
  [ISongs.FeatureReTrigger]: [265000, 433.3333333333371],
  [ISongs.MPDisp]: [267000, 1147.12018140591],
  [ISongs.CannonBallFire]: [270000, 808.8435374149867],
  [ISongs.CannonBallLand]: [272000, 1024.4897959183845],
  [ISongs.TotalWinBanner]: [275000, 5644.036281179126],
  [ISongs.Win_End]: [282000, 933.46938775511037],
  [ISongs.Win_Loop]: [284000, 399.77324263037417, true],
  [ISongs.SFX_UI_BetChange]: [286000, 32.108843537400844],
  [ISongs.SFX_UI_Close]: [288000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [290000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [292000, 151.79138321997243],
  [ISongs.SFX_UI_MaxBet]: [294000, 122.94784580501528],
  [ISongs.SFX_UI_MenuOpen]: [296000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [298000, 249.61451247168043],
  [ISongs.SFX_UI_SpinStop]: [300000, 151.02040816327644],
  [ISongs.SFX_WIN_BIG]: [302000, 1622.358276643979],
  [ISongs.SFX_WIN_EPIC]: [305000, 1862.2675736961583],
  [ISongs.SFX_WIN_GREAT]: [308000, 2118.412698412726],
  [ISongs.SFX_WIN_MEGA]: [312000, 1696.190476190452],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BaseGameBGM_Base]: 0.3,
  [ISongs.BaseGameBGM_Melo]: 0.2,
  // [ISongs.FreeSpinBGM_Intro]: 0.3,
  [ISongs.FreeSpinBGM_Loop]: 0.2,
  // [ISongs.BigWin_Intro]: 0.3,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  // [ISongs.BigWin_Start]: 0.3,
  [ISongs.Scatter_01]: 0.5,
  [ISongs.Scatter_02]: 0.5,
  [ISongs.Scatter_03]: 0.5,
  [ISongs.Scatter_04]: 0.5,
  [ISongs.Scatter_05]: 0.5,
  [ISongs.LongSpin]: 0.3,
  [ISongs.FeatureTrigger]: 0.3,
  [ISongs.FeatureReTrigger]: 0.3,
  [ISongs.MPDisp]: 1,
  [ISongs.CannonBallFire]: 1,
  [ISongs.CannonBallLand]: 1,
  [ISongs.TotalWinBanner]: 0.5,
  [ISongs.Win_End]: 0.7,
  [ISongs.Win_Loop]: 0.5,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.4,
  [ISongs.SFX_UI_SpinStop]: 0.4,
  [ISongs.SFX_WIN_BIG]: 0.3,
  [ISongs.SFX_WIN_EPIC]: 0.3,
  [ISongs.SFX_WIN_GREAT]: 0.3,
  [ISongs.SFX_WIN_MEGA]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
