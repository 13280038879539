import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH } from '../config';

export const BANNER_POSITION_X = -5;
export const BANNER_POSITION_Y = 60;
export const BANNER_WIDTH = GAME_CONTAINER_WIDTH + 60;
export const BANNER_HEIGHT = GAME_CONTAINER_HEIGHT + 60;

// export const BANNER_BACKGROUND_COLOR = 0xffffff;

export const titlePosition = 80;
export const subtitlePosition = 215;
export const additionalPosition = 270;
export const buttonPosition = 365;

export const winTitlePosition = 155;
export const winSubtitlePosition = 300;
