import i18n from 'i18next';
import * as _ from 'lodash';
import * as PIXI from 'pixi.js';

import { isMobile } from 'mobile-device-detect';
import { EventTypes, FreeSpinsTitleProps } from '../../global.d';
import { setCurrentBonus } from '../../gql';
import { updateTextScale } from '../../utils/utils';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import ViewContainer from '../components/container';
import {
  eventManager,
  FREE_SPINS_TEXT_X,
  FREE_SPINS_TEXT_Y,
  GAME_CONTAINER_WIDTH,
} from '../config';
import {
  FREE_SPIN_ANIMATION_DELAY,
  FREE_SPIN_ANIMATION_LOOP,
  FREE_SPIN_ANIMATION_SCALE,
  FREE_SPINS_TITLE_POSITION_Y,
} from './helperConfig';
import { spinsStyle, textStyle } from './textStyles';

class FreeSpinsTitle extends ViewContainer {
  private text: PIXI.Text;

  private spins: PIXI.Text;

  private multiplier: PIXI.Text;

  private pulsAnimation: Animation | null = null;

  private spinsAmount: string;

  private currentSpin: string;

  private currentMultiplier: string;

  constructor(props: FreeSpinsTitleProps) {
    super();

    this.y = FREE_SPINS_TITLE_POSITION_Y;
    this.spinsAmount = `${setCurrentBonus().rounds}`;
    this.currentSpin = `${setCurrentBonus().currentRound}`;
    this.currentMultiplier = this.currentSpin;
    this.text = this.initText(props.text);
    this.spins = this.initSpins();
    this.multiplier = this.initMultiplier();
    this.zIndex = 3;
    this.x = 0;
    this.init();
    eventManager.addListener(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      (spins: string, curr: string): void => this.handleUpdate(spins, curr),
    );

    eventManager.addListener(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_MULTIPLIER,
      (): void => this.handleUpdateMultiplier(),
    );
  }

  private init(): void {
    this.addChild(this.text);
    this.addChild(this.spins);
    this.addChild(this.multiplier);
  }

  private initText(titleText?: string): PIXI.Text {
    const text = new PIXI.Text(i18n.t(titleText || ''), textStyle);
    text.anchor.set(0.5, 0.5);
    updateTextScale(
      text,
      isMobile ? 280 : 280,
      isMobile ? 150 : 250,
    );

    text.y = FREE_SPINS_TEXT_Y;
    text.x = FREE_SPINS_TEXT_X;

    return text;
  }

  private initSpins(): PIXI.Text {
    const spins = new PIXI.Text(
      `${this.currentSpin} / ${this.spinsAmount}`,
      spinsStyle,
    );
    updateTextScale(
      spins,
      isMobile ? 150 : 150,
      isMobile ? 150 : 250,
    );
    spins.x = this.text.x + this.text.width / 2 + spins.width / 2;
    spins.y = FREE_SPINS_TEXT_Y;
    spins.anchor.set(0.5, 0.5);

    return spins;
  }

  private initMultiplier(): PIXI.Text {
    const multiplier = new PIXI.Text(`x${this.currentMultiplier}`, textStyle);
    multiplier.y = FREE_SPINS_TEXT_Y;
    multiplier.x = GAME_CONTAINER_WIDTH - 15;
    multiplier.anchor.set(1, 0.5);

    return multiplier;
  }

  private handleUpdate(spins?: string, currentSpin?: string): void {
    if (this.currentSpin === currentSpin && this.spinsAmount === spins) {
      return;
    }

    this.currentSpin = currentSpin ?? this.currentSpin;
    this.spinsAmount = spins ?? this.spinsAmount;
    this.currentMultiplier = this.currentSpin;

    this.spins.text = `${currentSpin || this.currentSpin} / ${
      spins || this.spinsAmount
    }`;
    this.spins.x = this.text.x + this.text.width / 2 + this.spins.width / 2;
    this.pulsAnimation = createZoomAnimation(
      this.spins,
      FREE_SPIN_ANIMATION_SCALE,
      FREE_SPIN_ANIMATION_DELAY,
      FREE_SPIN_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
  }

  private handleUpdateMultiplier(): void {
    this.multiplier.text = `x${this.currentMultiplier}`;
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE);
    this.destroy({ children: true, texture: true, baseTexture: true });
  }
}

export default FreeSpinsTitle;
