class Loader extends PIXI.Sprite {
  private currentFrame = 0;

  private numberOfFrames = 30;

  private rerenderFrequency = 50;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private intervalId: any;

  constructor(size: number) {
    super();

    this.anchor.set(0.5, 0.5);
    this.width = size;
    this.height = size;
    this.x = 0;
    this.y = 0;
  }

  public nextFrame(): void {
    this.texture = PIXI.Loader.shared.resources.loaderSprite.spritesheet!.textures[
      `Load_${this.currentFrame}.png`
    ];

    if (this.currentFrame === this.numberOfFrames - 1) {
      this.currentFrame = 0;
    } else {
      this.currentFrame += 1;
    }
  }

  public animate(): void {
    this.intervalId = setInterval(() => {
      this.nextFrame();
    }, this.rerenderFrequency);
  }

  public stopAnimation(): void {
    clearInterval(this.intervalId);
  }
}

export default Loader;
