import AudioHowl from '@phoenix7dev/play-music';
import { Button } from '@phoenix7dev/shared-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { isMobile } from 'mobile-device-detect';

import { ISongs } from '../../config';
import AutoPlay from './AutoPlay';
import ButtonBehaviors from './ButtonBehaviors';
import Features from './Features';
import GameRules from './GameRules';
import styles from './info.module.scss';
import PayLines from './PayLines';
import PaytableComponent from './Paytable';

export const InfoIcon: React.FC<{
  setType: React.Dispatch<
    React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>
  >;
}> = ({ setType }) => (
  <Button
    intent="info"
    onClick={() => {
      setType('info');
      AudioHowl.play({ type: ISongs.SFX_UI_MenuOpen });
    }}
  />
);

const Paytable: React.FC<{
  setType: React.Dispatch<
    React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>
  >;
  show: boolean;
}> = ({ setType, show }) => {
  const { t } = useTranslation();

  const handleClosePopup = (): void => {
    AudioHowl.play({ type: ISongs.SFX_UI_Close });
    setTimeout(() => {
      if (
        !AudioHowl.isPlaying(ISongs.SFX_UI_MenuOpen) &&
        !AudioHowl.isPlaying(ISongs.SFX_UI_SpinStart)
      ) {
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    setType('menu');
  };

  const handleClickTab = (): void => {
    AudioHowl.play({ type: ISongs.SFX_UI_General });
  };
  const handleHoverTab = (): void => {
    if (!isMobile) {
      AudioHowl.play({ type: ISongs.SFX_UI_Hover });
    }
  };

  return (
    <div style={{ display: show ? 'flex' : 'none' }}>
      <Button
        intent="close"
        className={styles['close-btn']}
        onClick={handleClosePopup}
      />
      <Tabs
        className={styles.info}
        domRef={(elem) => {
          elem
            ?.querySelector(':scope > div')
            ?.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        }}
      >
        <TabList className={styles.header}>
          <Tab
            className={styles.item}
            onClick={handleClickTab}
            onMouseOver={handleHoverTab}
          >
            {t('infoTabPayTable')}
          </Tab>
          <Tab
            className={styles.item}
            onClick={handleClickTab}
            onMouseOver={handleHoverTab}
          >
            {t('infoTabFeatures')}
          </Tab>
          <Tab
            className={styles.item}
            onClick={handleClickTab}
            onMouseOver={handleHoverTab}
          >
            {t('infoTabGameRules')}
          </Tab>
          <Tab
            className={styles.item}
            onClick={handleClickTab}
            onMouseOver={handleHoverTab}
          >
            {t('infoTabPayLines')}
          </Tab>
          <Tab
            className={styles.item}
            onClick={handleClickTab}
            onMouseOver={handleHoverTab}
          >
            {t('infoTabButtonBehaviors')}
          </Tab>
          <Tab
            className={styles.item}
            onClick={handleClickTab}
            onMouseOver={handleHoverTab}
          >
            {t('infoTabAutoPlay')}
          </Tab>
        </TabList>
        <div className={styles.container}>
          <TabPanel>
            <PaytableComponent />
          </TabPanel>
          <TabPanel>
            <Features />
          </TabPanel>
          <TabPanel>
            <GameRules />
          </TabPanel>
          <TabPanel>
            <PayLines />
          </TabPanel>
          <TabPanel>
            <ButtonBehaviors />
          </TabPanel>
          <TabPanel>
            <AutoPlay />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default Paytable;
