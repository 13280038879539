import AudioHowl from '@phoenix7dev/play-music';

import { isMobile } from 'mobile-device-detect';
import Loader from '../../components/Loader/loader';
import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import {
  setGameMode,
  setIsDuringBigWinLoop,
  setIsProcessToGame,
  setIsShowSoundToast,
  setIsSoundOn,
  setSoundValue,
} from '../../gql';
import {
  calcPercentage,
  getPlayListOnBroken,
  isFreeSpinMode,
} from '../../utils';
import Slider from '../components/slider';
import { eventManager } from '../config';
import Button from '.';

class SoundBtn extends Button {
  private slider: Slider;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private loader: any = null;

  private sizeOnInit = 0;

  constructor() {
    super(setIsSoundOn() ? 'sound' : 'sound-off', 40);
    this.addChild(this.btn);
    this.interactive = true;
    this.visible = !isMobile;
    this.setError(AudioHowl.isRestricted && !this.isLoadedWithSoundOff);
    this.slider = new Slider(150, 25, 100, 60, true, true, (v: number) => {
      if (setIsProcessToGame() && AudioHowl.isRestricted && v) {
        const soundToPlay = isFreeSpinMode(setGameMode())
          ? getPlayListOnBroken(setIsDuringBigWinLoop())
          : [
              { type: ISongs.BaseGameBGM_Base },
              { type: ISongs.BaseGameBGM_Melo, volume: 0 },
            ];

        setIsSoundOn(true);
        AudioHowl.changeRestriction(
          false,
          soundToPlay,
          () => {},
          () => {},
        );

        return;
      }

      AudioHowl.setVolume(Number(v));
      if (v && !setIsSoundOn()) {
        setIsSoundOn(true);
      } else if (!v && setIsSoundOn()) {
        setIsSoundOn(false);
      }
    });
    this.slider.visible = false;
    this.addChild(this.slider);
    this.handleErrorPlay();
    this.initEvents();
    this.initSubscriptions();

    this.sizeOnInit = this.size;

    if (this.isLoadingWithSoundOff) {
      this.enableLoader(this.sizeOnInit);
    }
  }

  private enableLoader(size: number): void {
    this.loader = this.initLoader(size);
    this.loader.animate();

    this.addChild(this.loader);
  }

  private initLoader(size: number) {
    return new Loader(size);
  }

  private initEvents = (): void => {
    this.btn.on('click', () => this.handleClick());
    this.btn.on('touchstart', () => this.handleClick());
    this.on('mouseover', () => {
      if (this.isFreezed()) return;

      this.slider.visible = true;
    });
    this.on('mouseout', () => {
      this.slider.visible = false;
    });
  };

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.TOGGLE_SOUND, (isSoundOn: boolean) => {
      this.slider.setSliderVal(isSoundOn ? 100 : 0);

      this.updateIntent(isSoundOn ? 'sound' : 'sound-off');
      this.setError(AudioHowl.isRestricted);
    });

    eventManager.on(EventTypes.SOUND_INITIALIZED, (isError: boolean) => {
      this.setError(isError);

      if (this.loader) {
        this.children.pop();
        this.loader.stopAnimation();
        this.loader = null;
      }
    });

    eventManager.on(EventTypes.ENABLE_SOUND_LOADER, () => {
      if (!this.loader) {
        this.enableLoader(this.sizeOnInit);
      }
    });
  };

  private handleErrorPlay = (): void => {
    const fn = (v: boolean) => {
      if (setIsSoundOn()) {
        this.slider.setSliderVal(v ? 100 : 0);
      }
      this.setError(!v);
    };
    AudioHowl.playingListener(fn);
  };

  public isFreezed(): boolean {
    return AudioHowl.isRestricted && !this.isLoadedWithSoundOff;
  }

  private get isLoadingWithSoundOff(): boolean {
    return !AudioHowl.isInitialized && !setIsSoundOn();
  }

  private get isLoadedWithSoundOff(): boolean {
    return AudioHowl.isInitialized && !setIsSoundOn();
  }

  private get isChangeRestrictionByClickOnSoundButtonAllowed(): boolean {
    return (
      !AudioHowl.restrictionChangedOnIntroScreen &&
      AudioHowl.isInitialized &&
      setIsSoundOn()
    );
  }

  private handleClick = (): void => {
    if (
      this.isFreezed() &&
      !this.isChangeRestrictionByClickOnSoundButtonAllowed
    )
      return;

    const soundToPlay = isFreeSpinMode(setGameMode())
      ? getPlayListOnBroken(setIsDuringBigWinLoop())
      : [
          { type: ISongs.BaseGameBGM_Base },
          { type: ISongs.BaseGameBGM_Melo, volume: 0 },
        ];

    AudioHowl.setSoundState(!setIsSoundOn());

    if (AudioHowl.isRestricted) {
      if (setIsShowSoundToast()) {
        setIsShowSoundToast(false);
      }

      setIsSoundOn(true);
      setSoundValue(1);
      this.slider.setSliderVal(100);
      AudioHowl.changeRestriction(
        false,
        soundToPlay,
        () => {},
        () => {},
      );
    } else {
      setIsSoundOn(!setIsSoundOn());
      this.slider.setSliderVal(setIsSoundOn() ? 100 : 0);
      setSoundValue(setIsSoundOn() ? 1 : 0);
    }

    if (!this.slider.visible) {
      this.slider.visible = true;
    }
    this.updateIntent(setIsSoundOn() ? 'sound' : 'sound-off');
  };

  public handlePosition = (): void => {
    const x = calcPercentage(this.applicationSize.width, 2.8);
    const y = calcPercentage(this.applicationSize.height, 95.7);
    this.setSize(calcPercentage(this.applicationSize.width, 2.7));
    this.transform.scale.x = 1;
    this.transform.scale.y = 1;

    const sliderWidth = this.slider.getSize();
    const sliderPositionX = this.size / 2;
    const sliderPositionY = sliderWidth;

    this.slider.setPosition(
      this.size,
      -sliderPositionX,
      -sliderPositionY,
      x,
      y,
    );
    this.x = x;
    this.y = y;
  };
}

export default SoundBtn;
